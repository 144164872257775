import styled from 'styled-components'
import { FC, PropsWithChildren, useCallback, useState } from 'react'
import Footer from '../footer/Footer'
import Header from '../header/Header'
import BackgroundSVG from '../../../assets/bg.svg'

const ContainerStyled = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr auto;
  background: ${({ theme }) => theme.colors.blue.light} url(${BackgroundSVG}) no-repeat center center;
  background-size: cover;
`

const ContentWrapperStyled = styled.div`
  max-width: 1440px;;
  width: 100%;
  justify-self: center;
  padding: 60px 20px;

  @media (max-width: 768px) {
    padding: 40px 15px;
  }
`

const LayoutWithBackground: FC<PropsWithChildren> = ({ children }) => {
  const [render, setRender] = useState(false)
  const handleRender = useCallback(() => setRender((prevState) => !prevState), [])

  return (
    <ContainerStyled>
      <Header handleRender={handleRender} />
      <ContentWrapperStyled key={Number(render).toString()}>{children}</ContentWrapperStyled>
      <Footer />
    </ContainerStyled>
  )
}

export default LayoutWithBackground
