import styled from 'styled-components'
import { FC } from 'react'
import DatasetsTable from './DatasetsTable'
import Hint from '../../../shared/hint/Hint'
import { COMPARE_DATASETS_HINT } from '../constants'
import Spinner from '../../../shared/Spinner'
import Card from '../../../shared/Card'
import { useDatasetsContext } from '../state/useDatasets'

const CardStyled = styled(Card)`
  padding: 20px 0;
`

const TitleStyled = styled.h1`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-right: 4px;
  color: ${({ theme }) => theme.colors.black};
`

const TitleWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: minmax(min-content, max-content);
`

const SubTitleStyled = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grey.dark};
  margin: 6px 0 16px;
  display: block;
`

const InfoWrapperStyled = styled.div`
  padding: 0 20px;
`

const AvailableDatasets: FC<{ className?: string }> = ({ className }) => {
  const { isLoading } = useDatasetsContext()

  const content = (
    <>
      <InfoWrapperStyled>
        <TitleWrapperStyled>
          <TitleStyled>Compare datasets by key characteristics</TitleStyled>
          <Hint title={COMPARE_DATASETS_HINT} placement="top" maxWidth={414} withDefaultColor />
        </TitleWrapperStyled>
        <SubTitleStyled>Click on the Dataset link to see summary. Check boxes to compare datasets by key characteristics.</SubTitleStyled>
      </InfoWrapperStyled>
      <DatasetsTable />
    </>
  )

  return <CardStyled className={className}>{isLoading ? <Spinner /> : content}</CardStyled>
}

export default AvailableDatasets
