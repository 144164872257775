export enum ROUTES {
  LANDING_PAGE = '/landing',
  PILOT_PAGE = '/pilot',
  DEMO = '/demo',
  BETA = '/beta',
  LOGIN = '/login',
  DATASETS = '/datasets',
  ADMIN_PANEL = '/admin-panel',
  COMPARE_DATASETS = '/compare-datasets',
  KEYWORD_DETAILS = '/keyword-details',
  COMPARE_VARIABLES = '/compare-variables',
  COMPARE_VALUES = '/compare-values',
  ABOUT = '/about',
  TERMS_OF_USE = '/terms-of-use',
  MAINTENANCE = '/maintenance'
}

export enum PARAMS {
  DATASET_ID = 'datasetId'
}
