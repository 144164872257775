import styled from 'styled-components'
import { useState } from 'react'
import Form from './Form'

const Container = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding-bottom: 60px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.5;
  font-family: 'MuseoModerno', sans-serif;
  background: white;
  color: white;
  padding: 32px;
  border-radius: 10px;
  justify-content: space-between;
`

const Title = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

const Subtitle = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`

const ThankYouContainerStyled = styled.div`
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px;
  max-width: 750px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  color: ${({ theme }) => theme.colors.blue.primary};

`

const ThankYouTextStyled = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.blue.primary};

  span {
    color: ${({ theme }) => theme.colors.blue.primary};
  }

  @media (max-width: 768px) {
    font-size: 1.5rem
  }
`

const SubTextStyled = styled.p`
font-family: 'MuseoModerno', sans-serif;
font-size: 20px;
color: ${({ theme }) => theme.colors.black};


  @media (max-width: 768px) {
    font-size: 1rem
  }
`

const BetaPage = () => {
  const [submitState, setSubmitState] = useState<boolean>(false)

  return (
    <Container>
      {!submitState && (
        <Section>
          <LeftBlock>
            <Title>We're gearing up for beta testing HDS and seeking a few qualified research institutions to participate.</Title>
            <Subtitle>
              Interested in a free beta subscription for your institution?
              <br />
              Let us know!
            </Subtitle>
          </LeftBlock>
          <RightBlock>
            <Form onFormSubmit={() => setSubmitState(true)} />
          </RightBlock>
        </Section>
      )}
      {submitState && (
        <ThankYouContainerStyled>
          <ThankYouTextStyled>
            Thank you for your interest in a <span>Health Data Select beta</span> <br /> subscription for your institution.
          </ThankYouTextStyled>
          <SubTextStyled>We will be in touch!</SubTextStyled>
        </ThankYouContainerStyled>

      )}

    </Container>
  )
}

export default BetaPage
