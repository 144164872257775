import { useRef, useState } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  margin: 0 auto;
  padding: 60px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 10px 5px;
  }
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding-bottom: 60px;
  gap: 40px;
  flex-wrap: wrap;

  @media (max-width: 1280px) {
    flex-direction: column;
    padding-bottom: 0px;
    gap: 20px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: 'MuseoModerno', sans-serif;
  flex: 1;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'MuseoModerno', sans-serif;
  color: white;
  padding: 32px;
  border-radius: 10px;
  justify-content: space-between;

  @media (max-width: 1280px) {
    padding: 0;
  }
`

const Title = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

const Subtitle = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
`

const VideoStyled = styled.video`
  width: 960px;
  height: 620px;

  @media (max-width: 960px)
  {
    width: 100%;
    height: 100%;
  }
`

const DemoPage = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [showControls, setShowControls] = useState(false)

  const handleVideoClick = (e?: React.MouseEvent<HTMLVideoElement, MouseEvent>) => {
    const video = videoRef.current
    if (video && !showControls) {
      if (e) {
        e.preventDefault()
      }

      setShowControls(true)
      video.play().catch((error) => {
        console.error('Error playing video:', error)
      })
    }
  }

  return (
    <Container>
      <Section>
        <LeftBlock>
          <Title>Demo</Title>
          <Subtitle>See Health Data Select in action</Subtitle>
          <LinkStyled onClick={() => handleVideoClick()}>View demo</LinkStyled>
        </LeftBlock>
        <RightBlock>
          <VideoStyled ref={videoRef} poster="/images/Demo_Page_Image_Screenshot_01.png" controls={showControls} onClick={(e) => { handleVideoClick(e) }} style={{ cursor: 'pointer' }}>
            <source src="/videos/HDS_Video.mp4" type="video/mp4" />
            <track src="captions.vtt" kind="captions" srcLang="en" label="English" default />
            Your browser does not support video playback.
          </VideoStyled>
        </RightBlock>
      </Section>
    </Container>
  )
}

export default DemoPage
