import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'

const Container = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  padding: 140px 20px 110px 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`

const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding-bottom: 60px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: 'MuseoModerno', sans-serif;
  background: #2a4ba0;
  color: white;
  padding: 32px;
  margin-left: 64px;
  border-radius: 10px;
  justify-content: space-between;
  background: linear-gradient(to right bottom, ${({ theme }) => theme.colors.blue.primary}, #556CA6);

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`

const FlexBlockLeft = styled.div`
  display:flex;
  flex-direction: column;
  flex: 0.8;
  gap: 10px;
  align-items: flex-start;
`

const FlexBlockRight = styled.div`
  display:flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  align-items: flex-start;
`

const Title = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 48px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

const Subtitle = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`

const Button = styled.button`
  background: white;
  color: #2a4ba0;
  border: none;
  padding: 12px 24px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  align-self: flex-start;
  width: 164px;

  &:hover {
    background: #f0f0f0;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 200px;
  }
`

const SectionContainer = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
`

const Section = styled.div`
  display: flex;
  padding: 64px;
  flex-direction: row;
  gap: 64px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
`

const SectionTitle = styled.h2`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const SectionSubTitle = styled.h2`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;

  @media (max-width: 768px) {
    font-size: 18px;
  }
`

const SectionText = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 8px;

  color: ${({ theme }) => theme.colors.black};
  b {
    font-weight: 600;
    color: #1a1a1a;
  }
`

const SectionSubText = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding-bottom: 8px;
  font-weight: 400;

  color: ${({ theme }) => theme.colors.black};
  b {
    font-weight: 600;
    color: #1a1a1a;
  }
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
`

const ListStyled = styled.ul`
  margin-left: 40px;
`

const ItemStyled = styled.li`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`

const LandingPage = () => {
  const navigate = useNavigate()
  return (
    <Container>
      <HeroSection>
        <LeftBlock>
          <Title>Introducing the first complete search engine dedicated to US health datasets</Title>
          <Subtitle>
            Health Data Select (HDS) allows researchers to identify the datasets most relevant to their analysis,
            with access to all major datasets, variable-level detail, and regular updates
          </Subtitle>
        </LeftBlock>
        <RightBlock>
          <div>
            <h2 style={{ lineHeight: '48px', fontSize: '40px', fontWeight: 700, fontFamily: 'MuseoModerno' }}>
              Why become a beta institution?
            </h2>
            <ul style={{ lineHeight: '24px', fontSize: '18px', marginLeft: '20px', marginTop: '20px' }}>
              <li style={{ marginBottom: '10px' }}>Free subscription for qualifying institutions</li>
              <li style={{ marginBottom: '10px' }}>
                Access to the first complete search engine dedicated to US health datasets
              </li>
              <li>Help drive the future of health services research</li>
            </ul>
          </div>
          <Button style={{ marginLeft: 'auto' }} onClick={() => navigate(ROUTES.BETA)}>Join beta</Button>
        </RightBlock>
      </HeroSection>
      <SectionContainer>
        <Section>
          <FlexBlockLeft>
            <LinkStyled onClick={() => navigate(ROUTES.ABOUT)}><img src="/images/team_square_2.jpg" style={{ width: '100%', borderRadius: 8 }} alt="about" /></LinkStyled>
          </FlexBlockLeft>
          <FlexBlockRight>
            <SectionTitle>About</SectionTitle>
            <SectionText>
              The HDS search engine includes:
            </SectionText>
            <SectionSubTitle>
              All major datasets
            </SectionSubTitle>
            <SectionSubText>
              View and compare a curated and growing collection of every key publicly available dataset for health services research.
            </SectionSubText>
            <SectionSubTitle>
              Variable-level detail
            </SectionSubTitle>
            <SectionSubText>
              Explore and compare variables and their possible values, with the ability to filter results by dataset properties such
              as time, scope and granularity.
            </SectionSubText>
            <SectionSubTitle>
              Regular updates
            </SectionSubTitle>
            <SectionSubText style={{ marginBottom: 0 }}>
              Stay ahead of changing datasets and variables with timely updates.
            </SectionSubText>
            <LinkStyled onClick={() => navigate(ROUTES.ABOUT)}>Learn More</LinkStyled>
          </FlexBlockRight>
        </Section>

        <Section>
          <FlexBlockLeft>
            <SectionTitle>Demo</SectionTitle>
            <SectionText>See Health Data Select in action:</SectionText>
            <ListStyled>
              <ItemStyled>Compare datasets, variables, and possible values</ItemStyled>
              <ItemStyled>Filter by key characteristics</ItemStyled>
              <ItemStyled>Apply multiple search terms</ItemStyled>
              <ItemStyled>Sort results by most "hits"</ItemStyled>
              <ItemStyled>Display dataset summaries</ItemStyled>
            </ListStyled>

            <LinkStyled style={{ marginLeft: '35px' }} onClick={() => navigate(ROUTES.DEMO)}>View demo</LinkStyled>
          </FlexBlockLeft>
          <FlexBlockRight>
            <LinkStyled onClick={() => navigate(ROUTES.DEMO)}><img src="/images/home_tool.jpg" style={{ width: '100%', borderRadius: 8 }} alt="demo" /></LinkStyled>
          </FlexBlockRight>
        </Section>
        <Section>
          <FlexBlockLeft>
            <LinkStyled onClick={() => navigate(ROUTES.PILOT_PAGE)}><img src="/images/pilot_survey.jpg" style={{ width: '100%', borderRadius: 8 }} alt="pilot survey" /></LinkStyled>
          </FlexBlockLeft>
          <FlexBlockRight>
            <SectionTitle>Pilot Research Survey</SectionTitle>
            <SectionText style={{ paddingBottom: 0 }}>Before you do your research, <br /> we did ours.</SectionText>
            <LinkStyled onClick={() => navigate(ROUTES.PILOT_PAGE)}>Learn More</LinkStyled>
          </FlexBlockRight>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default LandingPage
