import styled from 'styled-components'

const Container = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  padding: 90px 20px 110px 20px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`

const TextWrapper = styled.div`
  flex: 1;
`

const LearnMoreWrapper = styled.div`
  flex: 1;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 15px;
  }
`

const Title = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }
`

const Subtitle = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const LearnMoreTitle = styled.h2`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.black};
  font-weight: 600;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: 26px;
  }
`

const LearnMoreText = styled.p`
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.black};

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

const PdfWrapper = styled.div`
  flex: 1;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
`

const PdfObject = styled.object`
  width: 100%;
  height: calc(100vh + 90vh);
  min-height: 100%;
  display: block;
  border: none;

  @media (max-width: 768px) {
    height: calc(100vh);
  }
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: underline;
`

const PilotPage = () => (
  <Container>
    <HeaderSection>
      <TextWrapper>
        <Title>Pilot Research Survey</Title>
        <Subtitle>
          We designed HDS with researchers’ and analysts’ most
          convenient and important functions and features in
          mind.
          <br />
          <br />
          But we wanted to be sure.
          <br />
          So before you do your research, we did ours:
        </Subtitle>
      </TextWrapper>

      <LearnMoreWrapper>
        <LearnMoreTitle>Learn More</LearnMoreTitle>
        <LearnMoreText>
          If you’d like even more in-depth detail about our methodology, process and
          results, <LinkStyled href="mailto:research@healthdataselect.com">email us</LinkStyled> and we’ll be happy to share the complete research report.
        </LearnMoreText>
      </LearnMoreWrapper>
    </HeaderSection>

    <PdfWrapper>
      <PdfObject data="Pilot_survey_research.pdf" type="application/pdf">
        <p>
          Alternative text - include a link <a href="Pilot_survey_research.pdf">to the PDF!</a>
        </p>
      </PdfObject>
    </PdfWrapper>
  </Container>
)

export default PilotPage
