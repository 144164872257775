import styled from 'styled-components'
import Card from '../../../shared/Card'
import Form from './Form'
import { ReactComponent as Logo } from '../../../../assets/Logo.svg'

const ContainerStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

const CardStyled = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  min-width: 300px;
  max-width: 400px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
`

const Title = styled.h2`
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
`

const LogoStyled = styled(Logo)`
  margin-top: 16px;
  margin-bottom: 16px;
`

const Login = () => (
  <ContainerStyled>
    <CardStyled>
      <Title>Welcome to</Title>
      <LogoStyled />
      <Form />
    </CardStyled>
  </ContainerStyled>
)

export default Login
