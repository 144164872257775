import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'
import { ReactComponent as Logo } from '../../../assets/Logo.svg'

const ContainerStyled = styled.div`
  box-shadow: 0 0 4px 0 rgba(55, 59, 62, 0.1);
  background: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: 100px;
  @media (max-width: 768px) {
    display: none;
  }
`

const ContentWrapperStyled = styled.div<{ $hasTripleColumns?: boolean }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-template-columns: ${({ $hasTripleColumns }) => ($hasTripleColumns ? 'repeat(3, 1fr)' : '1fr 1fr')};
  padding: 0 20px;
  font-size: 14px;
  line-height: 16px;
  height: 50%;
  max-width: ${({ theme }) => theme.maxContentWidth};
  margin: auto;
`

const LinksWrapperStyled = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 24px;
  justify-self: center;
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.grey.dark};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`

const EmailLinkStyled = styled(LinkStyled)`
  justify-self: end;
`

const MenuStyled = styled.nav`
  display: flex;
  justify-content: center;
  gap: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  margin-left: auto;
  a {
    color: ${({ theme }) => theme.colors.black};
    font-family: 'MuseoModerno', sans-serif;
    text-decoration: none;
  }
`

const Footer = () => {
  const navigate = useNavigate()

  return (
    <ContainerStyled>
      <ContentWrapperStyled>
        <Logo />
        <MenuStyled>
          <LinkStyled onClick={() => navigate(ROUTES.LANDING_PAGE)}>Home</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.ABOUT)}>About</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.DEMO)}>Demo</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.BETA)}>Beta</LinkStyled>
          <LinkStyled onClick={() => navigate(ROUTES.PILOT_PAGE)}>Pilot Survey</LinkStyled>
        </MenuStyled>
      </ContentWrapperStyled>
      <ContentWrapperStyled $hasTripleColumns>
        <span>© 2023 -2025 Stern Consulting LLC</span>
        <LinksWrapperStyled>
          <LinkStyled onClick={() => navigate(ROUTES.TERMS_OF_USE)}>Terms of Use</LinkStyled>
        </LinksWrapperStyled>
        <EmailLinkStyled href="mailto:help@healthdataselect.com">help@healthdataselect.com</EmailLinkStyled>
      </ContentWrapperStyled>
    </ContainerStyled>
  )
}

export default Footer
