import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import Layout from './components/features/layout/Layout'
import Login from './components/features/login/components/Login'
import AdminPanel from './components/features/adminPanel/components/AdminPanel'
import { PARAMS, ROUTES } from './constants/routes'
import Datasets from './components/features/datasets/components/Datasets'
import ProtectedRoutesGuard from './components/features/protectedRoutesGuard/ProtectedRoutesGuard'
import PublicRoutesGuard from './components/features/publicRoutesGuard/PublicRoutesGuard'
import RoleGuard from './components/features/roleGuard/RoleGuard'
import DatasetDetails from './components/features/datasetDetails/components/DatasetDetails'
import DatasetsComparison from './components/features/datasetsComparison/components/DatasetsComparison'
import DatasetsStateProvider from './components/features/datasets/components/DatasetsStateProvider'
import KeywordDetails from './components/features/keywordDetails/components/KeywordDetails'
import VariablesComparison from './components/features/variablesComparison/components/VariablesComparison'
import ValuesComparison from './components/features/valuesComparison/components/ValuesComparison'
import { ComparedVariablesProvider } from './components/features/valuesComparison/state/useComparedVariablesState'
import About from './components/features/about/About'
import TermsOfUse from './components/features/termsOfUse/components/TermsOfUse'
import Maintenance from './components/features/maintenance/components/Maintenance'
import LandingLayout from './components/features/layout/LandingLayout'
import LandingPage from './components/features/landing/LandingPage'
import PilotPage from './components/features/pilot/PilotPage'
import LayoutWithBackground from './components/features/layout/LayoutWithBackground'
import DemoPage from './components/features/demo/DemoPage'
import BetaPage from './components/features/beta/BetaPage'

const AppRoutes = () => (
  <Routes>
    <Route
      element={
        (
          <Layout>
            <Outlet />
          </Layout>
        )
      }
    >
      <Route element={<ProtectedRoutesGuard />}>
        <Route
          element={(
            <DatasetsStateProvider>
              <Outlet />
            </DatasetsStateProvider>
          )}
        >
          <Route path={ROUTES.COMPARE_DATASETS} element={<DatasetsComparison />} />
          <Route path={ROUTES.DATASETS} element={<Datasets />} />
          <Route
            element={
              (
                <ComparedVariablesProvider>
                  <Outlet />
                </ComparedVariablesProvider>
              )
            }
          >
            <Route path={`${ROUTES.DATASETS}/:${PARAMS.DATASET_ID}`} element={<DatasetDetails />} />
            <Route path={ROUTES.KEYWORD_DETAILS} element={<KeywordDetails />} />
            <Route path={ROUTES.COMPARE_VARIABLES} element={<VariablesComparison />} />
            <Route path={ROUTES.COMPARE_VALUES} element={<ValuesComparison />} />
          </Route>
        </Route>
        <Route
          path={ROUTES.ADMIN_PANEL}
          element={
            (
              <RoleGuard>
                <AdminPanel />
              </RoleGuard>
            )
          }
        />
      </Route>

      <Route path={ROUTES.TERMS_OF_USE} element={<TermsOfUse />} />
      <Route
        path="*"
        element={<Navigate to={ROUTES.LANDING_PAGE} replace />}
      />
    </Route>
    <Route
      element={
        (
          <LandingLayout>
            <Outlet />
          </LandingLayout>
        )
      }
    >
      <Route path={ROUTES.ABOUT} element={<About />} />
      <Route path={ROUTES.LANDING_PAGE} element={<LandingPage />} />
      <Route path={ROUTES.PILOT_PAGE} element={<PilotPage />} />
    </Route>
    <Route
      element={
        (
          <LayoutWithBackground>
            <Outlet />
          </LayoutWithBackground>
        )
      }
    >
      <Route element={<PublicRoutesGuard />}>
        <Route path={ROUTES.LOGIN} element={<Login />} />
      </Route>
      <Route path={ROUTES.DEMO} element={<DemoPage />} />
      <Route path={ROUTES.BETA} element={<BetaPage />} />
    </Route>
    <Route path={ROUTES.MAINTENANCE} element={<Maintenance />} />
  </Routes>
)

export default AppRoutes
