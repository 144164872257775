import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes'

const Container = styled.div`
  max-width: 1560px;
  margin: 0 auto;
  padding: 147px 20px 110px 20px;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`

const HeroSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    padding-bottom: 20px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content:end;
`

const RightBlock = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const FlexBlockImg = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.8;
  gap: 10px;
  align-items: flex-start;
`

const FlexBlockLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  align-items: flex-start;
`

const FlexBlockRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  align-items: flex-start;
`

const Title = styled.h1`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 48px;
  line-height: 56px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 600;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 40px;
  }
`

const Subtitle = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.black};
`

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  border-radius: 10px;
`

const Section = styled.div`
  display: flex;
  padding: 64px;
  flex-direction: row;
  gap: 64px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
`

const SectionTitle = styled.h2`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 40px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.blue.primary};
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const SectionText = styled.p`
  font-family: 'MuseoModerno', sans-serif;
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};

  b {
    font-weight: 600;
    color: #1a1a1a;
  }
`

const LinkStyled = styled.a`
  color: ${({ theme }) => theme.colors.blue.primary};
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
  text-decoration: underline;
  margin: 0 5px;
`

const ImageStyled = styled.img`
  width: 373px;
  height: 142px;
  border-radius: 8;
  margin-left: auto;

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin-left: 0;
  }
`
const AboutPage = () => {
  const navigate = useNavigate()

  return (
    <Container>
      <HeroSection>
        <LeftBlock>
          <Title>About</Title>
          <Subtitle>
            Health Data Select (HDS) is a search engine
            allowing researchers to identify the datasets
            most relevant to their analysis.
          </Subtitle>
        </LeftBlock>
        <RightBlock>
          <ImageStyled
            src="/images/about_header.png"
            alt="about"
          />
        </RightBlock>
      </HeroSection>
      <SectionContainer>
        <Section>
          <FlexBlockImg>
            <img src="/images/tool.jpg" style={{ width: '100%', borderRadius: 8 }} alt="about" />
          </FlexBlockImg>
          <FlexBlockRight>
            <SectionTitle>The Tool</SectionTitle>
            <SectionText>
              With access to all major datasets, users can view and compare a curated and growing
              collection of every key publicly available dataset for health services research.
              <br />
              <br />
              Variable-level detail allows users to explore and compare variables and their
              possible values, with the ability to filter results by dataset properties such as
              time, scope and granularity.
              <br />
              <br />
              And the site is updated regularly to stay ahead of changing datasets and variables.
              <br />
              <br />
              HDS will soon enter a beta testing cycle.
            </SectionText>
          </FlexBlockRight>
        </Section>
        <Section>
          <FlexBlockLeft>
            <SectionTitle>The Datasets</SectionTitle>
            <SectionText>
              The determination of which datasets to include in HDS is based on several factors.
              First, datasets must be recognized by at least one authoritative source in the realm of
              health services research, such as a relevant government agency, or a selected library or
              academic institution.
              <br />
              <br />
              Where possible, objective measures of acceptance and impact are applied, such as the extent
              of recognition by authoritative sources and the number of citations in scholarly journals.
              Currently, the tool includes datasets that are ongoing, US-focused, and publicly available.
            </SectionText>
          </FlexBlockLeft>
          <FlexBlockRight>
            <SectionText>
              The composition of the datasets reflects a diversity of types, such as those with different
              collection mechanisms (surveys, administrative, and the like) and others with varying content
              focus (healthcare datasets as well as non-healthcare with relevant health variables).
              <br />
              <br />
              As a rule, the reported metadata is based on the most granular version of a dataset.
              And while in most cases this is the microdata, there are multiple entries for which aggregated
              data is the most granular version available.
              <br />
              <br />
              For more on the research that went into building HDS, see the
              <LinkStyled onClick={() => navigate(ROUTES.PILOT_PAGE)}>pilot survey</LinkStyled>
              we conducted to determine how health services researchers and analysts find datasets
              appropriate for their projects.
            </SectionText>
          </FlexBlockRight>
        </Section>
        <Section>
          <FlexBlockImg>
            <img src="/images/team.jpg" style={{ width: '100%', borderRadius: 8 }} alt="about" />
          </FlexBlockImg>
          <FlexBlockRight>
            <SectionTitle>The team</SectionTitle>
            <SectionText>
              HDS was created by Stern Consulting LLC , a company that has been providing data-driven
              support for the healthcare industry for over 20 years.
              <br />
              <br />
              The team includes seasoned researchers and users and represents voices from a broad spectrum
              of the health data community.
              <br />
              <br />
              The project director is Brian Williams, Stern Consulting’s vice president of analytic
              services.
            </SectionText>
          </FlexBlockRight>
        </Section>
        <Section>
          <FlexBlockLeft>
            <SectionTitle>The future of HDS</SectionTitle>
            <SectionText>
              New datasets are continuously being added to the tool, along with past and future iterations
              of the existing entries. Search features will evolve as beta and regular users provide their
              feedback. And we are currently developing an impact factor for datasets - similar to
              existing impact factors for academic journals - which we hope to include in future
              versions of HDS.
              <br />
              <br />
              We're gearing up for beta testing HDS and seeking a few qualified research institutions to
              participate.<LinkStyled onClick={() => navigate(ROUTES.BETA)}>Let us know</LinkStyled>
              if you are interested in a free beta subscription for your
              institution.
            </SectionText>
          </FlexBlockLeft>
          <FlexBlockImg>
            <img src="/images/future_hds.jpg" style={{ width: '100%', borderRadius: 8 }} alt="about" />
          </FlexBlockImg>
        </Section>
      </SectionContainer>
    </Container>
  )
}

export default AboutPage
