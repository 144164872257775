/* eslint-disable */
import { useForm } from 'react-hook-form'
import styled from 'styled-components'
import { DemoForm } from '../login/definitions'
import FormTextField from '../../shared/formControls/FormTextField'
import Button from '../../shared/Button'
import CustomTextArea from '../../shared/formControls/CustomTextArea'
import emailjs from 'emailjs-com';

const FormStyled = styled.form`
  display: grid;
  grid-row-gap: 16px;
  width: 100%;
`

export type FormProps = {
  onFormSubmit: () => void;
};

const Form = ({ onFormSubmit } : FormProps) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<DemoForm>({
    defaultValues: {
      email: '',
      fullname: '',
      comment: '',
      department: '',
      institution: ''
    },
    mode: 'onSubmit'
  })

  const onSubmit = (data: DemoForm) => {
    emailjs.send(
      'service_tnezrlu',
      'template_dbpfgvs',
      data,
      'myR08WOfefkwXqlCi'
    )
    .then(() => {
      reset();
      onFormSubmit();
    })
    .catch((err) => {
      console.error(err);
      alert('Failed to send email.');
    });
  }

  return (
    <FormStyled onSubmit={handleSubmit(onSubmit)}>
      <FormTextField
        id="fullName"
        label="Full Name*"
        fullWidth
        name="fullname"
        placeholder="Full Name"
        type="text"
        control={control}
        rules={{
          required: { value: true, message: 'Full Name is required' }
        }}
        error={!!errors.fullname}
        helperText={errors.fullname?.message}
      />

      <FormTextField
        id="email"
        label="Email*"
        fullWidth
        name="email"
        placeholder="email@domain.com"
        type="email"
        control={control}
        rules={{
          required: { value: true, message: 'Email is required' },
          pattern: {
            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: 'Invalid email address'
          }
        }}
        error={!!errors.email}
        helperText={errors.email?.message}
      />

      <FormTextField
        id="institution"
        label="Institution*"
        fullWidth
        name="institution"
        placeholder="Institution"
        type="text"
        control={control}
        rules={{
          required: { value: true, message: 'Institution is required' }
        }}
        error={!!errors.institution}
        helperText={errors.institution?.message}
      />

      <FormTextField
        id="department"
        label="Title/Department"
        fullWidth
        name="department"
        placeholder="Optional"
        type="text"
        control={control}
      />

      <CustomTextArea
        placeholder="Optional"
        minRows={9}
        name="comment"
        label="Comment"
        control={control}
      />

      <Button type="submit" style={{ marginRight: 'auto', minWidth: '164px' }}>
        Submit
      </Button>
    </FormStyled>
  )
}

export default Form