import styled from 'styled-components'
import { useLocation, useNavigate } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'
import ViewToggle from '../viewToggle/ViewToggle'
import { useAuthContext } from '../../../state/useAuth'
import Logout from '../logout/components/Logout'
import { ROUTES } from '../../../constants/routes'
import Feedback from '../feedback/components/Feedback'
import { ReactComponent as AdminLogo } from '../../../assets/Logo_badge.svg'
import { ReactComponent as LandingLogo } from '../../../assets/Logo_tagline.svg'
import Button from '../../shared/Button'
import { ReactComponent as MenuIcon } from '../../../assets/Menu_icon.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Close_icon.svg'
import useIsMobile from '../../../hooks/useIsMobile'

const ContentWrapperStyled = styled.div`
  max-width: ${({ theme }) => theme.maxContentWidth};
  align-items: center;
  padding: 0 20px;
  margin: auto;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr auto;
  }
`

const ContainerStyled = styled.div`
  height: 60px;
  box-shadow: 0 2px 4px 0 ${({ theme }) => theme.colors.shadow};
  background-color: ${({ theme }) => theme.colors.white};
  position: sticky;
  top: 0;
  z-index: 10002;
`

const ViewToggleStyled = styled(ViewToggle)`
  justify-self: center;
`

const LogoutButtonStyled = styled(Logout)`
  justify-self: end;
`

const LandingLogoStyled = styled(LandingLogo)`
  cursor: pointer;
  z-index: 199999;
  height: 60px;
  width: 280px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const AdminLogoStyled = styled(AdminLogo)`
  cursor: pointer;
  z-index: 199999;
  height: 60px;
  width: 280px;
  @media (max-width: 768px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const WrapStyled = styled.div`
  justify-self: flex-end;
  display: flex;
  align-items: center;
  gap: 12px;
`

const MenuStyled = styled.nav`
  display: flex;
  justify-content: center;
  gap: 56px;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;

  a {
    font-family: 'MuseoModerno', sans-serif;
    text-decoration: none;
    width: max-content
  }

  @media (max-width: 900px) {
    gap: 18px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`

const ActionsStyled = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 24px;
  margin-left: auto;

  @media (max-width: 768px) {
    display: none;
  }
`

const HamburgerMenu = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 4px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const MobileMenu = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 0 60px 0;
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  z-index: 10005;
`

const MobileNav = styled.nav`
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 30px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 40px;
  margin-top: 20px;
  padding: 0 20px;

  a {
    color: ${({ theme }) => theme.colors.blue.primary};
    text-decoration: none;
    font-family: 'MuseoModerno', sans-serif;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`

const DividerStyled = styled.div`
  width: 100%;
  min-height: 1px;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.blue.light};
`

const MobileMenuContent = styled.div`
  padding: 0 20px;
`

const LinkStyled = styled.a<{ active?: boolean }>`
  color: ${({ theme, active }) => (active ? theme.colors.blue.secondary : theme.colors.blue.primary)};
  text-decoration: none;
  cursor: pointer;

  &.mobile-link {
    color: ${({ theme, active }) => (active ? theme.colors.blue.primary : theme.colors.black)};
    font-size: 16px;
    font-weight: 400;
    font-family: 'MuseoModerno', sans-serif;
    padding-left: 20px;
  }

  &.mobile-login {
    color: ${({ theme, active }) => (active ? theme.colors.blue.primary : theme.colors.black)};
    font-size: 16px;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
    padding-left: 20px;
  }

  &.mobile-beta {
    color: ${({ theme }) => theme.colors.blue.primary};
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    maxWidth: 200px;
  }
`

type TProps = {
  handleRender: () => void
}

const Header: FC<TProps> = ({ handleRender }) => {
  const { authData } = useAuthContext()
  const isAuthorized = !!authData
  const isAdmin = authData?.userRole === 'admin'

  const location = useLocation()
  const currentPath = location.pathname

  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (!isMobile) {
      setMenuOpen(false)
    }
  }, [isMobile])

  const handleNavigate = () => {
    navigate(isAuthorized ? ROUTES.DATASETS : ROUTES.LANDING_PAGE)
    handleRender()
  }

  return (
    <ContainerStyled>
      <ContentWrapperStyled>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {authData && <AdminLogoStyled onClick={handleNavigate} />}
          {!authData && <LandingLogoStyled onClick={handleNavigate} />}

          {!isAdmin && (
            <HamburgerMenu onClick={() => setMenuOpen(true)}>
              <MenuIcon />
            </HamburgerMenu>
          )}
        </div>

        {isAdmin ? (
          <>
            <ViewToggleStyled />
            <WrapStyled>
              <Feedback />
              <LogoutButtonStyled />
            </WrapStyled>
          </>
        ) : (
          <>
            <MenuStyled>
              <LinkStyled onClick={() => navigate(ROUTES.LANDING_PAGE)} active={currentPath === ROUTES.LANDING_PAGE}>Home</LinkStyled>
              <LinkStyled onClick={() => navigate(ROUTES.ABOUT)} active={currentPath === ROUTES.ABOUT}>About</LinkStyled>
              <LinkStyled onClick={() => navigate(ROUTES.DEMO)} active={currentPath === ROUTES.DEMO}>Demo</LinkStyled>
              <LinkStyled onClick={() => navigate(ROUTES.PILOT_PAGE)} active={currentPath === ROUTES.PILOT_PAGE}>Pilot Survey</LinkStyled>
            </MenuStyled>

            {isAuthorized && (
              <WrapStyled>
                <Feedback />
                <LogoutButtonStyled />
              </WrapStyled>
            )}

            {!isAuthorized && (
              <ActionsStyled>
                <Button variant="primary" style={{ paddingLeft: 32, paddingRight: 32 }} onClick={() => navigate(ROUTES.BETA)}>
                  Join Beta
                </Button>
                <LinkStyled onClick={() => navigate(ROUTES.LOGIN)} active={currentPath === ROUTES.LOGIN}>Login</LinkStyled>
              </ActionsStyled>
            )}
          </>
        )}
      </ContentWrapperStyled>

      <MobileMenu isOpen={menuOpen}>
        <CloseButton onClick={() => setMenuOpen(false)}>
          <CloseIcon />
        </CloseButton>

        <DividerStyled />

        <MobileMenuContent>
          <MobileNav>
            <LinkStyled className="mobile-link" active={currentPath === ROUTES.LANDING_PAGE} onClick={() => { setMenuOpen(false); navigate(ROUTES.LANDING_PAGE) }}>Home</LinkStyled>
            <LinkStyled className="mobile-link" active={currentPath === ROUTES.ABOUT} onClick={() => { setMenuOpen(false); navigate(ROUTES.ABOUT) }}>About</LinkStyled>
            <LinkStyled className="mobile-link" active={currentPath === ROUTES.DEMO} onClick={() => { setMenuOpen(false); navigate(ROUTES.DEMO) }}>Demo</LinkStyled>
            <LinkStyled className="mobile-link" active={currentPath === ROUTES.BETA} onClick={() => { setMenuOpen(false); navigate(ROUTES.BETA) }}>Beta</LinkStyled>
            <LinkStyled className="mobile-link" active={currentPath === ROUTES.PILOT_PAGE} onClick={() => { setMenuOpen(false); navigate(ROUTES.PILOT_PAGE) }}>Pilot Survey</LinkStyled>
          </MobileNav>

          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', fontFamily: 'Roboto' }}>
            <Button
              variant="primary"
              className="mobile-beta"
              style={{ paddingLeft: 32, paddingRight: 32, width: '100%', maxWidth: '200px' }}
              onClick={() => {
                setMenuOpen(false)
                navigate(ROUTES.BETA)
              }}
            >
              Join Beta
            </Button>
            <LinkStyled className="mobile-login" active={currentPath === ROUTES.LOGIN} onClick={() => { setMenuOpen(false); navigate(ROUTES.LOGIN) }}>Login</LinkStyled>
          </div>
        </MobileMenuContent>
      </MobileMenu>
    </ContainerStyled>
  )
}

export default Header
