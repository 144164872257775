import { useQuery } from 'react-query'
import { apiClient } from '../api/apiClient'
import { TDatasetsVariablesData, TKeywordData } from '../definitions/variable'

const getKeywordDetails = async (
  data: TKeywordData,
  withValues: boolean,
  start?: number,
  count?: number
): Promise<TDatasetsVariablesData[]> =>
  await apiClient
    .post('/keywords/compare-across-dataset', data, {
      params: {
        values: withValues,
        start,
        count
      }
    })
    .then((response) => response?.data)

export const KEYWORD_DETAILS_QUERY_KEY = 'keywordDetails'

export const useKeywordDetailsQuery = (
  data: TKeywordData,
  // eslint-disable-next-line default-param-last
  withValues = false,
  // eslint-disable-next-line default-param-last
  enabled = true,
  start?: number,
  count?: number,
  page?: number
) => {
  const key = [KEYWORD_DETAILS_QUERY_KEY, { withValues, start, count, page: page ?? 1 }]
  return useQuery(key, () => getKeywordDetails(data, withValues, start, count), {
    cacheTime: 5000,
    enabled,
    keepPreviousData: true
  })
}
